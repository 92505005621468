const option = (value, display=value) => {
  const option = document.createElement('option')
  option.value = value
  option.innerText = display
  return option
}

export class DefaultConfigurationSelect extends HTMLElement {
  /* Preamble */

  #class
  #column

  constructor() {
    super()
    this.addEventListener('input', event => this.onInput(event))
  }

  connectedCallback() {
    if (document.readyState=='loading')
      return document.addEventListener('readystatechange', this.connectedCallback)

    this.changeClass()
  }

  /* Methods */

  onInput({target}) {
    if (target.matches('[name$="[target_class]"]'))
      this.changeClass()
    else if (target.matches('[name$="[target_column]"]'))
      this.changeColumn()
    else if (target.matches('[name$="[action]"]'))
      this.changeAction()
  }

  changeClass() {
    const changed = this.classInput.value
    if (this.#class !== changed) {
      const options = [...this.table.rows]
        .map(row => ['name', 'display'].map(name => row.cells.namedItem(name).innerText))
        .map(arr => option(...arr))
      const old_value = this.columnInput.value
      this.columnInput.replaceChildren(...options)
      this.columnInput.value = old_value
      if (!this.columnInput.value) this.columnInput.selectedIndex = 0
      this.#column = undefined
      this.changeColumn()
      this.#class = changed
    }
  }

  changeColumn() {
    const changed = this.columnInput.value
    if (this.#column !== changed) {
      [...this.actionInput.options]
        .find(option => option.value == 'disable_value')
        .toggleAttribute('disabled', this.type === 'boolean')
      this.changeAction()
      this.#column = changed
    }
  }

  changeAction() {
    const input = this.templateInput.cloneNode(true)

    input.name = this.valueInput.name

    if (input instanceof HTMLSelectElement) {
      input.value = this.valueInput.value
      if (!input.value) input.selectedIndex = 0
    } else if (input instanceof HTMLInputElement) {
      if (input.type === 'checkbox') {
        input.checked = this.valueInput.checked || this.valueInput.value === 'on'
      }
    }

    this.valueInput.replaceWith(input)

    input.closest(':has(label)').hidden = this.actionInput.value === 'disable_property'
  }

  /* Attributes */

  get table() { return this.template.children.namedItem(this.classInput.value) }
  get row() { return this.table.rows.namedItem(this.columnInput.value) }
  get templateInput() { return this.row.cells.namedItem('input').children[0] }
  get type() { return this.row.cells.namedItem('type').innerText }

  get classInput() { return this.querySelector('select[name$="[target_class]"]') }
  get columnInput() { return this.querySelector('select[name$="[target_column]"]') }
  get actionInput() { return this.querySelector('select[name$="[action]"]') }
  get valueInput() { return this.querySelector('[name$="[default_value]"]') }

  get template() {
    const template = this.querySelector(`[name="${templateName}"]`) || document.querySelector(`#${templateName}`)
    return template.content || template
  }
}

const templateName = 'default-configuration-template'
